import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useState, useEffect } from 'react'
import '../App.css'
import SideBar from './SideBar'
import Form from './Form'
import Login from './Login'
import Header from './Header'

const endpoints = {
  "localhost": "https://oc1y1dxf56.execute-api.us-east-1.amazonaws.com",
  "dev.cc.igeno.com": "",
  "test.cc.igeno.com" : "https://oc1y1dxf56.execute-api.us-east-1.amazonaws.com",
  "cc.igeno.com": ""
}

function App() {
  const [endpoint, setEndpoint] = useState('')
  useEffect(() => {
    const hostname = window.location.hostname
   
    console.log('*** hostname=',hostname)
    console.log('*** endpoints[hostname]=',endpoints[hostname])

    const endpointValue = endpoints[hostname] || ''

    setEndpoint(endpointValue)
  },[])

  return (
      <Router>
        <div id="wrapper">
          <SideBar/>
          <div id="content">
            <Routes>
              <Route path="/" exact element={<Header />}/>
              <Route path='/uploadForm' element={<Form endpoint={endpoint}/>}/> 
              <Route path='/login' element={<Login endpoint={endpoint}/>}/> 
            </Routes>
          </div>
          
        </div>
      </Router>
  )
}

export default App;
